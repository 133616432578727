import React from 'react';
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { responsive } from '../contexts/responsive';

const PortModal = ({ isOpen, setOpen, children }) => {
  return (
    <Modal
      isCentered
      size={responsive('xl', "6xl")}
      onClose={() => setOpen(null)}
      isOpen={isOpen}
      preserveScrollBarGap
      zIndex={1400}
    >
      <ModalContent border="2px solid" boxShadow={'1px 10px 3px #000'} borderRadius={'2em'} width="90%" height="90vh" overflow={'hidden'}>
        <ModalBody p="0">
          {children}
        </ModalBody>
        <ModalCloseButton
          right="0.5em"
          top={responsive('0.5em', '0.25em')}
          color="white"
          fontSize={responsive('1.25em', '2.8125em')}
          width={responsive('2.5rem', '2em')}
          height={responsive('2.5rem', '2em')}
          _hover={{ bg: 'black' }}
        />
      </ModalContent>
    </Modal>
  )
}

export default PortModal
