import React from 'react'
import { useHover } from 'react-use';
import { motion } from 'framer-motion'
import { Center } from '@chakra-ui/react'

import Box from '../../components/Box'
import Text from '../../components/Text'
import Flex from '../../components/Flex'
import Image from '../../components/Image'
import BackgroundImage from '../../components/BackgroundImage'
import { responsive } from '../../contexts/responsive'

import recommend from './recommend.svg'

const MotionBox = motion(Box)

const Card = ({ setOpen, i, d }) => {
  const element = (hovered) => <Box.FullAbs />
  const [hoverable, hovered] = useHover(element)
  return (
    <MotionBox
      width={responsive('100%', 1 / 3)}
      mt={responsive(i && '1.5em', i > 2 ? '3.875em' : '1em')}
      initial={{ scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0 }}
      layout
    >
      <Box height="100%" position="relative" cursor="pointer" onClick={() => setOpen(i.toString())} border={responsive('1.5px solid', '3px solid')} mx={responsive(0, '1.5vw')}>
        <Box.Relative borderBottom={'2px solid'}>
          <BackgroundImage src={d?.cover} ratio={680 / 580}>
            <Box.FullAbs bg="rgba(0,0,0,0.5)" opacity={hovered ? 1 : 0} transition="opacity 0.5s">
              <Center height={'100%'}>
                <Box fontSize={responsive('1.5em', '2em')} color="white" borderRadius={'full'} border={responsive('1.5px solid', '3px solid')} px={responsive('2em', '2.5em')} py="0.375rem">完整展開</Box>
              </Center>
            </Box.FullAbs>
          </BackgroundImage>
          {d?.recommend && (
            <Box.Absolute bg="black" px="0.25em" py="0.625em" borderRadius="0 0 0 1.5em" top="0" right="0" width="20.5%">
              <Image src={recommend} />
            </Box.Absolute>
          )}
        </Box.Relative>
        <Box px="1em" py="1em">
          <Text mt="1em" fontSize={responsive('1em', '1.5em')}>{d.name}</Text>
          <Box bg="custom.sliderGray" height="1px" wid th="2.25em" mt="1.5em" mb="0.625em" />
          <Flex flexWrap={'wrap'}>
            {d?.tag.map((t, k) => (
              <Box
                color="custom.bgGray"
                pr="0.375rem"
                fontSize={responsive('0.75em', '1.25em')}
                key={k}
              >#{t}</Box>
            ))}
          </Flex>
        </Box>
        {hoverable}
      </Box>
    </MotionBox>
  )
}

export default Card
