import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

import Box from './Box'

const ptr = keyframes`
0%,
100% {
  opacity: 1;
}
50% {
  opacity: 0;
}
`

const ShiningAnimate = styled(Box)`
  transition: all 300ms;
  animation: ${ptr} 1.5s ${props => props.delay} infinite;
`

export default ShiningAnimate
