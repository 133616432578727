import React, { useState, useMemo, useRef, useEffect } from 'react'
import range from 'lodash/range'
import {
  Center,
  IconButton,
  Spinner,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { TiMediaPlayReverse } from 'react-icons/ti'
import { compact, includes } from 'lodash'
import { useSearchParam } from 'react-use'

import Box from '../../components/Box'
import Text from '../../components/Text'
import Flex from '../../components/Flex'
import Button from '../../components/Button'
import PortModal from '../../components/PortModal'
import CollapseEx from '../../components/Collapse'
import ShiningAnimate from '../../components/ShiningAnimate'
import { Media, responsive } from '../../contexts/responsive'

import Filter from './Filter'
import useHeader from '../../contexts/header/useHeader'
import Smile from './Smile'
import Card from './Card'
import usePortfolio from './usePortfolio'
// import data from './behance.json'

const icons = [
  { Icon: Smile, type: 'recommend' },
  { Icon: Filter, type: 'filter' },
]

const categories = [
  {
    label: '看看有相同溝通目的的作品',
    data: [
      { label: '強調產品優勢', trackerName: 'collection_purpose_product' },
      { label: '說明事件(物)脈絡', trackerName: 'collection_purpose_story' },
      { label: '說服參與行動', trackerName: 'collection_purpose_action' },
      { label: '呈現數據報告', trackerName: 'collection_purpose_data' },
      { label: '引導思考改變', trackerName: 'collection_purpose_thinking' },
      { label: '教學方法指南', trackerName: 'collection_purpose_method' },
      { label: '想到就做的創作', trackerName: 'collection_purpose_creation' },
    ],
  },
  {
    label: '看看不同方法如何協助你溝通',
    data: [
      { label: '資訊圖表', trackerName: 'collection_form_infographic' },
      { label: '懶人包', trackerName: 'collection_form_fordummies' },
      { label: '說明動畫', trackerName: 'collection_form_animation' },
      { label: '互動式網頁/測驗', trackerName: 'collection_form_website' },
      { label: '手冊/成果報告', trackerName: 'collection_form_report' },
      { label: '簡報分鏡與製作', trackerName: 'collection_form_slide' },
      { label: '展場中的資訊圖表', trackerName: 'collection_form_exhibition' },
    ],
  },
  {
    label: '看看相同主題的作品',
    data: [
      { label: '生活休閒', trackerName: 'collection_field_life' },
      { label: '資訊科技', trackerName: 'collection_field_technology' },
      { label: '財金經濟', trackerName: 'collection_field_finance' },
      { label: '教育職訓', trackerName: 'collection_field_education' },
      { label: '環境自然', trackerName: 'collection_field_nature' },
      { label: '社會人文', trackerName: 'collection_field_social' },
      { label: '媒體時事', trackerName: 'collection_field_news' },
      { label: '法律公部門', trackerName: 'collection_field_law' },
      { label: '醫療保健', trackerName: 'collection_field_medical' },
      { label: '運動飲食', trackerName: 'collection_field_sportnfood' },
      { label: '其他', trackerName: 'collection_field_other' },
    ],
  },
]

const MotionFlex = motion(Flex)

const FilterButton = ({
  filters,
  type,
  Icon,
  setFilter,
  index,
  active,
  ...props
}) => (
  <Button.Icon
    bg={active || filters.includes(type) ? 'black' : 'transparent'}
    border={responsive('1.5px solid black', '3px solid black')}
    color={active || filters.includes(type) ? 'white' : 'black'}
    borderRadius={'full'}
    px="0"
    py="0"
    width={responsive('3em', '5em')}
    height={responsive('3em', '5em')}
    icon={
      <Icon
        width={responsive('3em', '5em')}
        height={responsive('3em', '5em')}
      />
    }
    onClick={() => {
      if (filters.includes(type)) {
        const index = filters.findIndex((d) => d == type)
        filters.splice(index, 1)
        setFilter([...filters])
        return
      }
      filters.push(type)
      setFilter([...filters])
    }}
    ml={index && '2'}
    {...props}
  />
)

const Projects = () => {
  const loaded = useRef(false)
  const defaultProject = useSearchParam('id')
  const { headerHeight } = useHeader()
  const [filters, setFilter] = useState([])
  // const [selects, setSelect] = useState([])
  const [target, setTarget] = useState([])
  const [category, setCategry] = useState([])
  const [fields, setField] = useState([])
  const [isOpen, setOpen] = useState()
  const filterRef = useRef()
  const { data } = usePortfolio()
  // console.log(data)
  useOutsideClick({
    ref: filterRef,
    handler: () => setFilter((f) => f.filter((d) => d !== 'filter')),
  })
  const selectTools = useMemo(
    () => [
      { func: setTarget, value: target },
      { func: setCategry, value: category },
      { func: setField, value: fields },
    ],
    [target, category, fields]
  )
  const selects = useMemo(
    () => [...target, ...category, ...fields],
    [target, category, fields]
  )
  const transformData = useMemo(
    () =>
      data
        ?.map((d) => ({
          ...d,
          target: compact([d.目的1, d.目的2, d.目的3]),
          category: compact([d.作品分類1, d.作品分類2, d.作品分類3, d.作品分類4]),
          field: d.主題領域,
          recommend: Boolean(d.RE推推),
          tag: compact([
            d.目的1,
            d.目的2,
            d.目的3,
            d.作品分類1,
            d.作品分類2,
            d.作品分類3,
            d.作品分類4,
            d.主題領域,
          ]),
        }))
        ?.filter((d) => d.id && d.isHidden !== "TRUE"),
    [data]
  )
  const filterData = useMemo(
    () =>
      transformData
        ?.filter((d) => (filters.includes('recommend') ? d.recommend : d))
        .filter((d) =>
          target.length ? target.some((s) => includes(d.target, s)) : d
        )
        .filter((d) =>
          category.length ? category.some((s) => includes(d.category, s)) : d
        )
        .filter((d) => (fields.length ? includes(fields, d.field) : d)),
    [filters, target, category, fields, transformData]
  )
  useEffect(() => {
    if (transformData && !loaded.current) {
      loaded.current = true
      const defaultProjIndex = transformData.findIndex((d) => d.id == defaultProject)
      if (defaultProjIndex > -1) {
        setOpen(defaultProjIndex.toString())
      }
    }
  }, [transformData])
  if (!filterData) {
    return (
      <Center py="3em">
        <VStack fontSize="lg">
          <Spinner size="lg" />
          <Text>作品載入中...</Text>
        </VStack>
      </Center>
    )
  }
  return (
    <Box pt={responsive('2.5em', '5.25em')} pb={responsive('3.125em', '7em')}>
      <Flex
        position={'sticky'}
        top={headerHeight}
        justifyContent={'space-between'}
        bg="custom.filterBg"
        borderBottom={'1px solid'}
        py={responsive('0.75em', '1.25em')}
        px={responsive('2em', '7.5vw')}
        zIndex={10}
      >
        <Flex>
          {icons.map(({ Icon, type }, k) => (
            <Box key={k}>
              <Media greaterThanOrEqual="lg">
                <FilterButton
                  _hover={{
                    bg: 'black',
                    color: 'white',
                    borderColor: 'black',
                  }}
                  trackerName="collection_recommend"
                  filters={filters}
                  type={type}
                  Icon={Icon}
                  setFilter={setFilter}
                  active={k && selects.length}
                  index={k}
                />
              </Media>
              <Media lessThan="lg">
                <FilterButton
                  filters={filters}
                  type={type}
                  Icon={Icon}
                  setFilter={setFilter}
                  index={k}
                />
              </Media>
            </Box>
          ))}
        </Flex>
        <Flex color="custom.bgGray" alignItems={'center'}>
          {range(3).map((d) => (
            <ShiningAnimate
              mx="-0.125em"
              fontSize={responsive('1em', '2.25em')}
              delay={`${(3 - +d) * 0.33}s`}
              key={d}
            >
              <TiMediaPlayReverse />
            </ShiningAnimate>
          ))}
          <Text fontSize={responsive('1em', '2.25em')}>點選開始篩選</Text>
        </Flex>
        {filters.includes('filter') && (
          <Box.Absolute
            bg="white"
            top="calc(100% + 1px)"
            right="0"
            left="0"
            ref={filterRef}
          >
            {categories.map((t, key) => (
              <CollapseEx
                selectState={selectTools[key]}
                key={key}
                data={t.data}
              >
                {t.label}
              </CollapseEx>
            ))}
          </Box.Absolute>
        )}
      </Flex>
      <Box pt="1.5em" px={responsive('2.25em', '7.5vw')}>
        <Text color="custom.bgGray" fontSize={responsive('1em', '2.25em')}>
          所有作品
        </Text>
        <MotionFlex
          flexDirection={responsive('column', 'row')}
          mx={responsive(0, '-1.5vw')}
          flexWrap="wrap"
          pt={responsive('0.75em', 0)}
        >
          <AnimatePresence>
            {filterData &&
              filterData.map((d, i) => (
                <Card i={i} d={d} setOpen={setOpen} key={d.id} />
              ))}
          </AnimatePresence>
        </MotionFlex>
      </Box>
      <PortModal isOpen={isOpen} setOpen={setOpen}>
        <Box.Absolute
          top="0"
          bg="black"
          left="0"
          right="0"
          height="7.5em"
          fontSize="1rem"
        >
          <Center
            px="2em"
            height="100%"
            color="white"
            fontSize={responsive('1.5em', '2em')}
          >
            {filterData?.[isOpen]?.name}
          </Center>
        </Box.Absolute>
        <iframe
          title={filterData?.[isOpen]?.name}
          src={`${filterData?.[isOpen]?.url}?iframe=1&ilo0=${+isOpen + 1}`}
          width="100%"
          height="100%"
          sandbox="allow-same-origin allow-scripts allow-forms"
        />
      </PortModal>
    </Box>
  )
}

export default Projects
